<template>
    <div class="container_detail" style="background: #1c1c1c;">
        <div class="header_Css">
            <div @click="toBack"><img src="../assets/back.png" alt=""></div>
            <h2>Bônus de suporte semanal de perdas</h2>
            <span>Histórico</span>
        </div>
        <!-- <div style="height: 10px;"></div> -->
        <div class="JYJJ_css" style="margin: 10px" align="center">
            <div class="JYJJ_css_1"><span>Perdas da última semana <span style="color: green;">{{ resumeDetail.lastWeekLost }}</span></span>
                &nbsp;&nbsp; <span>Subsídios desta semana <span style="color: #feaf75;">{{ resumeDetail.reward }}</span></span></div>
            <div>
                <div v-show="rotaryType1" style="width: 300px;height: 320px">
                    <div id="app">
<!--                        <h1>剩余抽奖次数：{{ num }}</h1>-->
                        <div class="wheel-center2"></div>
                        <round-turntable-blue
                            ref="roundTurntableBlue"
                            :prizeData="blueReward"
                            :rotateCircle="rotateCircle"
                            :duringTime="duringTime"
                            @endRotation="endRotationBlue"
                            class="turntable">
                            <template slot="item" slot-scope="scope">
                                <div class="turntable-name">{{ scope.item.rotaryAmount|formatNumber }}</div>
                                <!--                <div class="turntable-img">-->
                                <!--                    <img :src="scope.item.prizeImg">-->
                                <!--                </div>-->
                            </template>
                        </round-turntable-blue>
                        <div @click="startRotationBlue" class="pointer"></div>
                    </div>
                </div>
                <div v-show="rotaryType2" style="width: 300px;height: 320px">
                    <div id="app2">
                        <!--                        <h1>剩余抽奖次数：{{ num }}</h1>-->
                        <div class="wheel-center22"></div>
                        <round-turntable-yellow
                            ref="roundTurntableYellow"
                            :prizeData="yellowReward"
                            :rotateCircle="rotateCircle"
                            :duringTime="duringTime"
                            @endRotation="endRotationYellow"
                            class="turntable">
                            <template slot="item" slot-scope="scope">
                                <div class="turntable-name">{{ scope.item.rotaryAmount|formatNumber }}</div>
                                <!--                <div class="turntable-img">-->
                                <!--                    <img :src="scope.item.prizeImg">-->
                                <!--                </div>-->
                            </template>
                        </round-turntable-yellow>
                        <div @click="startRotationYellow" class="pointer"></div>
                    </div>
                </div>
                <div v-show="rotaryType3" style="width: 300px;height: 320px">
                    <div id="app3">
                        <!--                        <h1>剩余抽奖次数：{{ num }}</h1>-->
                        <div class="wheel-center23"></div>
                        <round-turntable-purple
                            ref="roundTurntablePurple"
                            :prizeData="purpleReward"
                            :rotateCircle="rotateCircle"
                            :duringTime="duringTime"
                            @endRotation="endRotationPurple"
                            class="turntable">
                            <template slot="item" slot-scope="scope">
                                <div class="turntable-name">{{ scope.item.rotaryAmount|formatNumber }}</div>
                                <!--                <div class="turntable-img">-->
                                <!--                    <img :src="scope.item.prizeImg">-->
                                <!--                </div>-->
                            </template>
                        </round-turntable-purple>
                        <div @click="startRotationPurple" class="pointer"></div>
                    </div>
                </div>
            </div>
            <div>
                <ul style="padding-left: 10px;padding-top: 5px;">
                    <li class="rotaryButtonLi"><button :style="buttonStyle1" @click="blueRotary">Roda de Prata</button></li>
                    <li class="rotaryButtonLi"><button :style="buttonStyle2" @click="yellowRotary">Roda de Ouro</button></li>
                    <li class="rotaryButtonLi"><button :style="buttonStyle3" @click="purpleRotary">Roda de Diamante</button></li>
                </ul>
            </div>
            <div class="JYJJ_css_2">
                <div class="JYJJ_css_2_1" align="left">
                    <span style="font-weight: 700;padding-left: 5px;padding-top: 5px;text-align: left">Instruções Do Evento:</span>
                    <div style="padding: 5px;text-align: left">1.Esta atividade pertence a "Resgate Humanitário, Resgate de Emergência em Rios e Lagos".
                        Quando você Semana passada perde 10 ou mais no jogo, você pode obter a quantia correspondente de
                        dinheiro de resgate. O nível do bônus é determinado pelo valor do perda. Quanto maior a perda,
                        maior será o valor do resgate, até 7777;<br>2.Não há restrições de plataforma para perdas. A
                        atualização das recompensas está prevista para ocorrer por volta das 02:00h de Próxima semana.
                        Aguarde a distribuição das recompensas.<br>3.Fórmula de cálculo: valor do bônus = valor da perda
                        dentro do período (deduzindo o desconto), correspondendo ao bônus do nível;<br>4.As recompensas
                        recebidas só podem ser resgatadas após Próxima segunda-feira07:00:00. O resgate só pode ser
                        feito manualmente no APP/iOS、APP/Android、PC/Windows.<br>5.As recompensas recebidas expirarão 1
                        dias após o término de cada ciclo. As recompensas expiradas serão canceladas
                        automaticamente;<br>6.O bônus (excluindo o principal) deste evento requer 3 apostas válidas para
                        ser sacado, e as apostas não são limitadas a plataforma do jogo;<br>7.Este evento é limitado a
                        operações normais realizadas pelo titular da conta. É proibido alugar, usar plug-ins externos,
                        robôs, apostar em contas diferentes, brushing mútuo, arbitragem, interface, protocolo,
                        exploração de vulnerabilidades, controle de grupo ou outros meios técnicos para participar. Caso
                        contrário, as recompensas serão canceladas ou deduzidas, a conta será congelada ou até mesmo
                        adicionada à lista negra;<br>8.Para evitar diferenças na compreensão do texto, a plataforma
                        reserva-se o direito de interpretação final deste evento.<br></div>


                </div>
            </div>

            <div class="JYJJ_css_3">
                <div class="JYJJ_css_3_1" @click="toBack">
                    Retornar
                </div>
                <div v-if="resumeDetail.status!=1" class="JYJJ_css_3_2">
                    Coletar Tudo
                </div>
                <div v-if="resumeDetail.status==1" class="JYJJ_css_3_1" @click="receiveResume">
                    Coletar Tudo
                </div>
            </div>

        </div>



        <div class="qqq" style="margin-bottom: 80px;">
            <img class="bom_img" src="@/assets/images/home/活动01.png" alt="">
            <img class="bom_img" src="@/assets/images/home/活动02.png" alt="">
            <img class="bom_img" src="@/assets/images/home/活动03.png" alt="">
            <img class="bom_img" src="@/assets/images/home/活动04.png" alt="">
            <img class="bom_img" src="@/assets/images/home/活动05.png" alt="">
            <img class="bom_img" src="@/assets/images/home/活动06.png" alt="">
        </div>

    </div>
</template>

<script>
import { getRotaryInfo,getRotaryAware,getRotaryReward} from '@/api/index'
import roundTurntableBlue from './RotaryBlue';
import roundTurntableYellow from './RotaryYellow';
import roundTurntablePurple from './RotaryPurple';
export default {
    components: {
        roundTurntableBlue,
        roundTurntableYellow,
        roundTurntablePurple
    },
    computed:{
        buttonStyle1() {
            return {
                backgroundColor: this.isActive1 ? '#36a3f7' : 'rgba(29,29,29,0.31)',
                width: "90%",
                height: "100%",
                cursor: 'pointer'
            };
        },
        buttonStyle2() {
            return {
                backgroundColor: this.isActive2 ? '#ffa130' : 'rgba(29,29,29,0.31)',
                width: "90%",
                height: "100%",
                cursor: 'pointer'
            };
        },
        buttonStyle3() {
            return {
                backgroundColor: this.isActive3 ? '#ef82ff' : 'rgba(29,29,29,0.31)',
                width: "90%",
                height: "100%",
                cursor: 'pointer'
            };
        },
    },
    data() {
        return {
          boxList: {},
          totalLine: 0,
          timerId:null,
          activityId:this.$route.query.id,
          isActive1: true,
          isActive2: false,
          isActive3: false,
          resumeDetail:{},
          rotaryType1: true,
          rotaryType2: false,
          rotaryType3: false,
          // 转动的圈数
          rotateCircle: 4,
          // 转动需要持续的时间（s）
          duringTime: 4.5,
          // 中奖的奖品的index
          prizeIndex: -1,
          // 用来锁定转盘，避免同时多次点击转动
          isLocking: false,
          // 剩余抽奖次数
          num: 2,
          blueNum:2,
          yellowNum:2,
          purpleNum:2,
          blueIndex:0,
          yellowIndex:0,
          purpleIndex:0,
          // 转盘上的奖品数据
          blueReward:[],
          yellowReward:[],
          purpleReward:[],
        }
    },
    mounted() {
        this.getActivityDetail();
    },
    beforeDestroy() {
        if (this.timerId) {
            clearTimeout(this.timerId);
            this.timerId = null;
        }
    },

    methods: {
        startRotationBlue() {
            // 如果还不可以转动
            if (!this.canBeRotatedBlue()) {
                return false;
            }
            // 开始转动
            // 先上锁
            this.isLocking = true;
            // 设置在哪里停下，应该与后台交互，这里随机抽取0~5
            getRotaryAware("1").then(res => {
                this.blueIndex = res.data
                if (res.code=="200"){
                    // const index = 5;
                    // 成功后次数减少一次
                    this.blueNum--;
                    this.prizeIndex = this.blueIndex;
                    // 告诉子组件，开始转动了
                    this.$refs.roundTurntableBlue.rotate(this.blueIndex);
                }

            });

        },
        // 已经转动完转盘触发的函数
        endRotationBlue() {
            getRotaryReward("1",`${this.blueReward[this.prizeIndex].rotaryAmount}`).then(res=>{
                if (res.code=="200"){
                    // 提示中奖
                    this.$toast.success(`恭喜您获奖啦,您的奖品是：${this.blueReward[this.prizeIndex].rotaryAmount}`);
                    // 解锁
                    this.isLocking = false;
                }else {
                    // 解锁
                    this.isLocking = false;
                }
            })

        },
        // 判断是否可以转动
        canBeRotatedBlue() {
            if (this.blueNum <= 0) {
                this.$toast.success('已经木有次数了！');
                return false;
            }
            if (this.isLocking) {
                return false;
            }
            return true;
        },
        startRotationYellow() {
            // 如果还不可以转动
            if (!this.canBeRotatedYellow()) {
                return false;
            }
            // 开始转动
            // 先上锁
            this.isLocking = true;
            // 设置在哪里停下，应该与后台交互，这里随机抽取0~5
            getRotaryAware("2").then(res => {
                this.blueIndex = res.data
                if (res.code=="200"){
                    // 成功后次数减少一次
                    this.yellowNum--;
                    this.prizeIndex = this.yellowIndex;
                    // 告诉子组件，开始转动了
                    this.$refs.roundTurntableYellow.rotate(this.yellowIndex);
                }

            });
        },
        // 已经转动完转盘触发的函数
        endRotationYellow() {
            getRotaryReward("2",`${this.blueReward[this.prizeIndex].rotaryAmount}`).then(res=>{
                if (res.code=="200"){
                    // 提示中奖
                    this.$toast.success(`恭喜您获奖啦,您的奖品是：${this.blueReward[this.prizeIndex].rotaryAmount}`);
                    // 解锁
                    this.isLocking = false;
                }else {
                    // 解锁
                    this.isLocking = false;
                }
            })
        },
        // 判断是否可以转动
        canBeRotatedYellow() {
            if (this.yellowNum <= 0) {
                this.$toast.success('已经木有次数了！');
                return false;
            }
            if (this.isLocking) {
                return false;
            }
            return true;
        },
        startRotationPurple() {
            // 如果还不可以转动
            if (!this.canBeRotatedPurple()) {
                return false;
            }
            // 开始转动
            // 先上锁
            this.isLocking = true;
            // 设置在哪里停下，应该与后台交互，这里随机抽取0~5
            getRotaryAware("3").then(res => {
                this.blueIndex = res.data
                if (res.code=="200"){
                    // 成功后次数减少一次
                    this.purpleNum--;
                    this.prizeIndex = this.purpleIndex;
                    // 告诉子组件，开始转动了
                    this.$refs.roundTurntablePurple.rotate(this.purpleIndex);
                }

            });
        },
        // 已经转动完转盘触发的函数
        endRotationPurple() {
            getRotaryReward("3",`${this.blueReward[this.prizeIndex].rotaryAmount}`).then(res=>{
                if (res.code=="200"){
                    // 提示中奖
                    this.$toast.success(`恭喜您获奖啦,您的奖品是：${this.blueReward[this.prizeIndex].rotaryAmount}`);
                    // 解锁
                    this.isLocking = false;
                }else {
                    // 解锁
                    this.isLocking = false;
                }
            })
        },
        // 判断是否可以转动
        canBeRotatedPurple() {
            if (this.purpleNum <= 0) {
                this.$toast.success('已经木有次数了！');
                return false;
            }
            if (this.isLocking) {
                return false;
            }
            return true;
        },
        blueRotary(){
            this.rotaryType1 = true;
            this.rotaryType2 = false;
            this.rotaryType3 = false;
            this.isActive1 = true;
            this.isActive2 = false;
            this.isActive3 = false;
        },
        yellowRotary(){
            this.rotaryType1 = false;
            this.rotaryType2 = true;
            this.rotaryType3 = false;
            this.isActive1 = false;
            this.isActive2 = true;
            this.isActive3 = false;
        },
        purpleRotary(){
            this.rotaryType1 = false;
            this.rotaryType2 = false;
            this.rotaryType3 = true;
            this.isActive1 = false;
            this.isActive2 = false;
            this.isActive3 = true;

        },
        getActivityDetail() {
            getRotaryInfo().then(res => {
                this.blueReward = res.data.blueItem
                this.yellowReward = res.data.yellowItem
                this.purpleReward = res.data.purpleItem
            })
        },
        toBack() {
            this.$router.push('/home/event')
        }
    }
}

</script>

<style lang="scss" scoped>

.rotaryButtonLi{
    display: inline-block;
    width: 33%;
    height: 50px;
    font-size: 10px;
}

.container_detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header_Css {
    background: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    width: 100%;
    min-height: 40px;

    img {
        cursor: pointer;
    }

    h2 {
        color: #fff;
        font-size: 15px;
        width: 260px;
        text-align: center;
    }

    span {
        color: #feaf75;
        cursor: pointer;
    }
}

.JYJJ_css {
    .JYJJ_css_1 {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 10px 0;
    }


    .JYJJ_css_2 {
        margin: 10px 0;

        .JYJJ_css_2_1 {
            background: #303030;
        }
    }
    .JYJJ_css_3{
        height: 67px;
        width: 100%;
        background: #303030;
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: fixed;
        bottom: 0;
        left: 0;
        .JYJJ_css_3_1{
            cursor: pointer;
            border-radius: 10px;
            color: #cb8d71;
            width: 180px;
            border: 1px solid #cb8d71;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
        }
        .JYJJ_css_3_2{
            cursor: pointer;
            border-radius: 10px;
            color: #ccc;
            width: 180px;
            border: 1px solid #999;
            background: #999;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
        }
    }
}

.shunhua{
    transition: 0.1s;
}
.wheel-center22 {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    border-radius: 50%;
    left: calc(50% - 90px);
    top: 85px;
    width: 180px;
    height: 190px;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../assets/images/wheel/zphd_zz_s2.png');
    //transform: rotate(360deg);
}
.wheel-center23 {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    border-radius: 50%;
    left: calc(50% - 90px);
    top: 85px;
    width: 180px;
    height: 190px;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../assets/images/wheel/zphd_zz_s3.png');
    //transform: rotate(360deg);
}
.qqq {
    overflow: hidden;
    overflow-x: auto;
    width: 100%;
    display: flex;

    .bom_img {
        width: 104px;
        height: 50px;
        margin: 0 5px
    }
}
td{
    width:50%
}
@media (min-width:751px) {
  .JYJJ_css_3{
    height: 67px;
    width: 435px !important;
    background: #303030;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 50% !important;
    transform: translateX(-50%);
  }
}


#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin: 0;
    padding: 0;
    /*background-color: #04be02;*/
    .wheel-center2 {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        border-radius: 50%;
        left: calc(50% - 90px);
        top: 85px;
        width: 180px;
        height: 190px;
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        /*background-color: #C03639;*/
        background-image: url('../assets/images/wheel/zphd_zz_s1.png');
        //transform: rotate(360deg);
    }
    .turntable {
        /*position: absolute;*/
        left: calc(50% - 150px);
        top: calc(50% - 250px);
        border-radius: 50%;
        width: 300px;
        height: 300px;
        /*z-index: 2;*/
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        background-position: center;
        background-size: 100%;
        background-image: url("../assets/images/wheel/lan.png");

    }
    .turntable-name {
        position: absolute;
        left: 10px;
        top: 20px;
        width: calc(100% - 20px);
        font-size: 20px;
        text-align: center;
        color: #fff;
    }

    /*.turntable-img {*/
    /*    position: absolute;*/
    /*    !*要居中就要50% - 宽度 / 2*!*/
    /*    left: calc(50% - 80px / 2);*/
    /*    top: 60px;*/
    /*    width: 80px;*/
    /*    height: 80px;*/
    /*    img {*/
    /*        display: inline-block;*/
    /*        width: 100%;*/
    /*        height: 100%;*/
    /*    }*/
    /*}*/

    .pointer {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        left: calc(50% - 43px);
        top: 190px;
        z-index: 4;
        width: 100px;
        height: 100px;
        background-image: url('../assets/images/wheel/zphd_ljcj_s1.png');
        background-size: contain;
        background-repeat: no-repeat;
    }
}
#app2 {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin: 0;
    padding: 0;
    .turntable {
        position: absolute;
        left: calc(50% - 150px);
        top: calc(50% - 250px);
        border-radius: 50%;
        width: 300px;
        height: 300px;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        background-position: center;
        background-size: 100%;
        background-image: url("../assets/images/wheel/huang.png");
    }
    .turntable-name {
        position: absolute;
        left: 10px;
        top: 20px;
        width: calc(100% - 20px);
        font-size: 20px;
        text-align: center;
        color: #fff;
    }

    /*.turntable-img {*/
    /*    position: absolute;*/
    /*    !*要居中就要50% - 宽度 / 2*!*/
    /*    left: calc(50% - 80px / 2);*/
    /*    top: 60px;*/
    /*    width: 80px;*/
    /*    height: 80px;*/
    /*    img {*/
    /*        display: inline-block;*/
    /*        width: 100%;*/
    /*        height: 100%;*/
    /*    }*/
    /*}*/

    .pointer {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        left: calc(50% - 43px);
        top: 190px;
        z-index: 4;
        width: 100px;
        height: 100px;
        background-image: url("../assets/images/wheel/zphd_ljcj_s2.png");
        background-size: contain;
        background-repeat: no-repeat;
    }
}
#app3 {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin: 0;
    padding: 0;
    .turntable {
        position: absolute;
        left: calc(50% - 150px);
        top: calc(50% - 250px);
        border-radius: 50%;
        width: 300px;
        height: 300px;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        background-position: center;
        background-size: 100%;
        background-image: url("../assets/images/wheel/zi.png");

    }
    .turntable-name {
        /*background: pink;*/
        position: absolute;
        left: 10px;
        top: 20px;
        width: calc(100% - 20px);
        font-size: 20px;
        text-align: center;
        color: #fff;
    }

    /*.turntable-img {*/
    /*    position: absolute;*/
    /*    !*要居中就要50% - 宽度 / 2*!*/
    /*    left: calc(50% - 80px / 2);*/
    /*    top: 60px;*/
    /*    width: 80px;*/
    /*    height: 80px;*/
    /*    img {*/
    /*        display: inline-block;*/
    /*        width: 100%;*/
    /*        height: 100%;*/
    /*    }*/
    /*}*/

    .pointer {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        left: calc(50% - 43px);
        top: 190px;
        z-index: 4;
        width: 100px;
        height: 100px;
        background-image: url('../assets/images/wheel/zphd_ljcj_s3.png');
        background-size: contain;
        background-repeat: no-repeat;
    }
}

</style>
